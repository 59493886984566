export default {
  CacheSize: 96,
  ReaderReadAHead: 95,
  UseDisk: false,
  UploadRateLimit: 0,
  TorrentsSavePath: '',
  ConnectionsLimit: 23,
  DhtConnectionLimit: 500,
  DisableDHT: false,
  DisablePEX: false,
  DisableTCP: false,
  DisableUPNP: false,
  DisableUTP: true,
  DisableUpload: false,
  DownloadRateLimit: 0,
  EnableDebug: false,
  EnableIPv6: false,
  ForceEncrypt: false,
  PeersListenPort: 0,
  PreloadCache: 0,
  RemoveCacheOnDrop: false,
  RetrackersMode: 1,
  Strategy: 0,
  TorrentDisconnectTimeout: 30,
}
